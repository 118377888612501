/* MainPage.css */
:root {
  --primary-color: #0a0a0a;
  --secondary-color: #1a1a1a;
  --accent-color: #00ff9d;
  --accent-color-2: #0066ff;
  --accent-color-3: #ff0066;
  --text-color: #ffffff;
  --box-bg: rgba(26, 26, 26, 0.8);
  --transition-speed: 0.3s;
  --neon-glow: 0 0 10px rgba(0, 255, 157, 0.5),
               0 0 20px rgba(0, 255, 157, 0.3),
               0 0 30px rgba(0, 255, 157, 0.1);
  --neon-glow-intense: 0 0 10px rgba(0, 255, 157, 0.7),
                      0 0 20px rgba(0, 255, 157, 0.5),
                      0 0 30px rgba(0, 255, 157, 0.3),
                      0 0 40px rgba(0, 255, 157, 0.1);
}

/* Reset default margins and padding */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background-color: var(--primary-color);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, sans-serif;
  color: var(--text-color);
}

/* Main Page Styling */
.main-page {
  min-height: 100vh;
  background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
  position: relative;
  overflow: hidden;
}

/* Banner Section */
.banner-section {
  width: 100%;
  height: 300px;
  position: relative;
  overflow: hidden;
  margin-bottom: 2rem;
}

.banner-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.banner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom, rgba(26, 26, 26, 0.7), var(--primary-color));
}

/* Profile Section */
.profile-section {
  display: grid;
  grid-template-columns: auto 1.5fr;
  gap: 3rem;
  max-width: 1200px;
  margin: -100px auto 3rem;
  padding: 2.5rem;
  padding-bottom: 4rem;
  background: rgba(26, 26, 26, 0.85);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  position: relative;
  z-index: 2;
}

.profile-images {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.profile-avatar {
  width: 280px;
  height: 280px;
  border-radius: 20px;
  overflow: hidden;
  border: 3px solid var(--accent-color);
  box-shadow: var(--neon-glow);
  transition: all 0.3s ease;
  margin-top: -50px;
  position: relative;
}

.profile-avatar::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border-radius: 22px;
  background: linear-gradient(45deg, 
    var(--accent-color), 
    var(--accent-color-2), 
    var(--accent-color-3),
    var(--accent-color)
  );
  background-size: 400% 400%;
  z-index: -1;
  animation: gradientBorder 8s ease infinite;
  filter: blur(4px);
}

@keyframes gradientBorder {
  0% {
    background-position: 0% 50%;
    opacity: 0.7;
  }
  50% {
    background-position: 100% 50%;
    opacity: 1;
  }
  100% {
    background-position: 0% 50%;
    opacity: 0.7;
  }
}

.profile-avatar:hover {
  transform: scale(1.02) rotate(2deg);
  box-shadow: 0 0 30px rgba(0, 255, 157, 0.5),
              inset 0 0 30px rgba(0, 255, 157, 0.3);
}

.profile-avatar:hover::before {
  animation: borderGlow 1.5s ease-in-out infinite;
}

.avatar-image {
  width: 130%;
  height: 130%;
  object-fit: cover;
  object-position: center top;
  transition: transform 0.3s ease;
  margin: -15% 0 0 -15%;
}

.profile-info {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding-top: 1.5rem;
}

.profile-info h1 {
  font-size: 5rem;
  font-weight: 900;
  color: #fff;
  margin-bottom: 1rem;
  letter-spacing: 10px;
  position: relative;
  z-index: 1;
}

.profile-info h1::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -8px;
  width: 100%;
  height: 2px;
  background: linear-gradient(45deg, 
    var(--accent-color) 0%,
    var(--accent-color-2) 50%,
    var(--accent-color-3) 100%
  );
  background-size: 200% 200%;
  animation: gradientShift 5s ease infinite;
  box-shadow:
    0 0 5px var(--accent-color),
    0 0 10px var(--accent-color-2),
    0 0 15px var(--accent-color-3);
}

@keyframes gradientShift {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.tagline {
  font-size: 1.3rem;
  color: var(--text-color);
  opacity: 0.9;
  font-weight: 500;
  margin-top: 0.5rem;
  letter-spacing: 1px;
}

.profile-description {
  font-size: 1.1rem;
  line-height: 1.8;
  color: var(--text-color);
  opacity: 0.8;
  max-width: 90%;
}

/* Social Links */
.social-links {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.social-link {
  color: var(--text-color);
  font-size: 1.8rem;
  transition: all 0.3s ease;
  filter: drop-shadow(0 0 2px var(--accent-color));
}

.social-link:hover {
  color: var(--accent-color);
  transform: translateY(-2px) scale(1.1);
  filter: drop-shadow(0 0 5px var(--accent-color));
}

/* Navigation Menu */
.box-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.5rem;
  max-width: 1200px;
  margin: 0 auto 3rem;
  padding: 0 2rem;
  position: relative;
  z-index: 2;
}

/* Box Styling */
.box {
  background: var(--box-bg);
  backdrop-filter: blur(10px);
  color: var(--text-color);
  padding: 1.5rem;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
  transition: all var(--transition-speed) ease;
  border: 1px solid rgba(255, 255, 255, 0.1);
  height: 150px;
  position: relative;
  overflow: hidden;
}

.box::after {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(
    45deg,
    transparent,
    rgba(0, 255, 187, 0.1),
    transparent
  );
  transform: rotate(45deg);
  animation: lightPass 3s ease-in-out infinite;
}

@keyframes lightPass {
  0% {
    transform: translateX(-100%) rotate(45deg);
  }
  100% {
    transform: translateX(100%) rotate(45deg);
  }
}

.box:hover {
  transform: translateY(-5px) scale(1.02);
  border-color: var(--accent-color);
  box-shadow: var(--neon-glow-intense);
}

.box-icon {
  font-size: 2rem;
  color: var(--accent-color);
  transition: all 0.3s ease;
  filter: drop-shadow(0 0 5px var(--accent-color));
}

.box:hover .box-icon {
  transform: scale(1.1);
  color: var(--accent-color-2);
  filter: drop-shadow(0 0 8px var(--accent-color-2));
}

.box-title {
  font-size: 1.2rem;
  font-weight: 600;
  text-align: center;
  margin: 0;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .profile-section {
    grid-template-columns: 1fr;
    gap: 2rem;
    margin-top: -50px;
    padding: 2rem;
  }

  .profile-avatar {
    margin: -30px auto 0;
  }

  .profile-info {
    text-align: center;
    align-items: center;
  }

  .profile-info h1 {
    font-size: 4.5rem;
    letter-spacing: 8px;
  }

  .profile-description {
    max-width: 100%;
    text-align: center;
  }

  .social-links {
    justify-content: center;
  }

  .box-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .banner-section {
    height: 200px;
  }

  .profile-section {
    margin-top: -30px;
    padding: 1.5rem;
  }

  .profile-info h1 {
    font-size: 3.5rem;
    letter-spacing: 6px;
  }

  .profile-info h1::after {
    height: 1.5px;
    bottom: -6px;
  }

  .tagline {
    font-size: 1.1rem;
  }

  .profile-description {
    font-size: 1rem;
    line-height: 1.6;
  }

  .box-container {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .box {
    height: 120px;
  }

  .box-icon {
    font-size: 1.8rem;
  }

  .box-title {
    font-size: 1rem;
  }

  .profile-avatar {
    width: 180px;
    height: 180px;
    margin: -30px auto 0;
  }
}

@media (max-width: 768px) and (orientation: portrait) {
  .nav-icons {
    left: 1rem;
    transform: translateY(-50%);
  }

  .nav-icons a {
    font-size: 1.2rem;
    margin: 0.5rem 0;
  }

  .nav-icons a:hover {
    transform: translateX(0.3rem);
  }

  .overview {
    flex-direction: column;
    padding: 1rem;
    margin: 1rem;
    width: calc(100% - 2rem);
  }

  .overview-item {
    width: 100%;
    margin: 0.5rem 0;
    padding: 1rem;
    min-width: unset;
  }

  .overview-item h3 {
    font-size: 1.2rem;
  }

  .overview-item p {
    font-size: 1rem;
  }

  .box-container {
    padding: 0 1rem;
  }

  .box {
    padding: 1.2rem;
  }

  .box-title {
    font-size: 1.1rem;
  }
}

@media (max-width: 480px) and (orientation: portrait) {
  .nav-icons {
    left: 0.8rem;
  }

  .nav-icons a {
    font-size: 1.1rem;
    margin: 0.4rem 0;
  }

  .overview {
    padding: 0.8rem;
    margin: 0.8rem;
  }

  .overview-item {
    padding: 0.8rem;
  }

  .overview-item h3 {
    font-size: 1.1rem;
  }

  .overview-item p {
    font-size: 0.9rem;
  }

  .box-container {
    padding: 0 0.8rem;
  }

  .box {
    padding: 1rem;
  }

  .box-title {
    font-size: 1rem;
  }
}

@media (max-width: 768px) and (orientation: landscape) {
  .overview {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    padding: 1rem;
    margin: 1rem;
    width: calc(100% - 2rem);
  }

  .overview-item {
    flex: 1 1 calc(50% - 1rem);
    min-width: 200px;
    margin: 0;
  }
}

@media (max-width: 480px) and (orientation: landscape) {
  .overview-item {
    flex: 1 1 100%;
    min-width: unset;
  }
}

/* Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideInFromLeft {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideInFromRight {
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes floatAnimation {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

@keyframes glowPulse {
  0%, 100% {
    box-shadow: 0 0 20px rgba(0, 255, 157, 0.3);
  }
  50% {
    box-shadow: 0 0 30px rgba(0, 255, 157, 0.5);
  }
}

@keyframes gradientShift {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Apply animations to elements */
.banner-section {
  animation: fadeIn 1s ease-out;
}

.profile-section {
  animation: fadeIn 1s ease-out 0.3s backwards;
}

.profile-avatar {
  animation: slideInFromLeft 1s ease-out 0.5s backwards;
}

.profile-avatar:hover {
  transform: scale(1.02) rotate(2deg);
}

.profile-info {
  animation: slideInFromRight 1s ease-out 0.5s backwards;
}

.profile-info h1 {
  background: none;
  animation: none;
}

.social-links {
  animation: fadeIn 1s ease-out 0.7s backwards;
}

.social-link:hover {
  animation: floatAnimation 1s ease infinite;
}

.box-container {
  animation: fadeIn 1s ease-out 0.9s backwards;
}

.box {
  position: relative;
  overflow: hidden;
  animation: glowPulse 3s infinite;
}

.box::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    120deg,
    transparent,
    rgba(255, 255, 255, 0.1),
    transparent
  );
  transition: 0.5s;
}

.box:hover::before {
  left: 100%;
}

.box:hover {
  transform: translateY(-5px) scale(1.02);
  border-color: var(--accent-color);
  box-shadow: 0 5px 15px rgba(0, 255, 157, 0.3);
}

.box:hover .box-icon {
  animation: floatAnimation 1s ease infinite;
}

/* Banner overlay animation */
.banner-overlay {
  background: linear-gradient(
    to bottom,
    rgba(26, 26, 26, 0.7),
    var(--primary-color)
  );
  animation: fadeIn 1.5s ease-out;
}

/* Main page background animation */
.main-page {
  background: linear-gradient(
    135deg,
    var(--primary-color),
    var(--secondary-color),
    #1a1a2e
  );
  background-size: 400% 400%;
  animation: gradientShift 15s ease infinite;
}

/* Responsive animations */
@media (max-width: 768px) {
  .profile-avatar,
  .profile-info {
    animation: fadeIn 1s ease-out;
  }
}

/* Scrollbar Styling */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: var(--primary-color);
}

::-webkit-scrollbar-thumb {
  background: var(--accent-color);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #00ccff;
}

/* Add hover area */
.box-wrapper::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  pointer-events: none;
}

.overview {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3rem;
    gap: 0.8rem;
    flex-wrap: wrap;
}

.overview-item {
    flex: 1;
    min-width: 120px;
    text-align: center;
    padding: 1rem;
    background: rgba(255, 255, 255, 0.03);
    border-radius: 15px;
    border: 1px solid rgba(255, 255, 255, 0.05);
    transition: all 0.3s ease;
    animation: fadeIn 0.5s ease-out;
}

.overview-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(74, 144, 226, 0.1);
    border-color: rgba(74, 144, 226, 0.2);
}

.overview-item h3 {
    color: #4a90e2;
    font-size: 1rem;
    margin-bottom: 0.4rem;
}

.overview-item p {
    color: #b0b0b0;
    font-size: 0.9rem;
    margin: 0;
}

/* Responsive Design */
@media (max-width: 768px) {
    .overview {
        flex-direction: column;
        align-items: stretch;
        gap: 0.6rem;
    }

    .overview-item {
        width: 100%;
        min-width: unset;
        padding: 0.8rem;
    }

    .overview-item h3 {
        font-size: 0.95rem;
    }

    .overview-item p {
        font-size: 0.85rem;
    }
}

@media (max-width: 480px) {
    .overview {
        gap: 0.5rem;
    }

    .overview-item {
        padding: 0.7rem;
    }
}

.nav-icons {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  left: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  z-index: 100;
}

.nav-icon {
  color: var(--accent-color);
  font-size: 1.5rem;
  transition: all 0.3s ease;
  opacity: 0.8;
  cursor: pointer;
}

.nav-icon:hover {
  color: var(--text-color);
  transform: scale(1.1);
  opacity: 1;
}

/* Hide navigation icons when all content is on the same side */
@media (max-width: 768px) {
  .nav-icons {
    display: none;
  }
}

/* Show navigation icons only when content is alternating sides */
@media (min-width: 769px) {
  .nav-icons {
    display: flex;
  }
  
  /* Adjust position based on content layout */
  .nav-icons.left-side {
    left: 1.5rem;
  }
  
  .nav-icons.right-side {
    left: auto;
    right: 1.5rem;
  }
}

.me-page {
  padding-left: 5rem;
  width: calc(100% - 5rem);
  box-sizing: border-box;
}

.me-page .content-section {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
}

@media (max-width: 1024px) {
  .me-page {
    padding-left: 4rem;
    width: calc(100% - 4rem);
  }
}

@media (max-width: 768px) {
  .me-page {
    padding-left: 3.5rem;
    width: calc(100% - 3.5rem);
  }

  .nav-icons {
    left: 0.8rem;
    transform: translateY(-50%);
  }

  .nav-icons a {
    font-size: 1.2rem;
    margin: 0.5rem 0;
  }
}

@media (max-width: 480px) {
  .me-page {
    padding-left: 3rem;
    width: calc(100% - 3rem);
  }

  .nav-icons {
    left: 0.5rem;
  }

  .nav-icons a {
    font-size: 1.1rem;
    margin: 0.4rem 0;
  }
}

/* Ensure nav icons don't overlap with content */
.nav-icons {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  z-index: 100;
  background: rgba(18, 18, 18, 0.8);
  padding: 0.5rem;
  border-radius: 0.5rem;
  backdrop-filter: blur(5px);
}
