.nft-page {
    min-height: 100vh;
    background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
    color: var(--text-color);
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, sans-serif;
    padding: 2rem;
    position: relative;
    overflow: hidden;
    max-width: 1200px;
    margin: 0 auto;
    background: var(--secondary-color);
}

.profile-header {
    text-align: center;
    margin-bottom: 4rem;
    animation: fadeIn 1s ease-out;
}

.profile-header h1 {
    font-size: 3rem;
    font-weight: 900;
    margin-bottom: 1.5rem;
    color: #fff;
    position: relative;
    display: inline-block;
}

.profile-header h1::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -8px;
    width: 100%;
    height: 2px;
    background: linear-gradient(45deg, 
        var(--accent-color) 0%,
        var(--accent-color-2) 50%,
        var(--accent-color-3) 100%
    );
    background-size: 200% 200%;
    animation: gradientShift 5s ease infinite;
    box-shadow:
        0 0 5px var(--accent-color),
        0 0 10px var(--accent-color-2),
        0 0 15px var(--accent-color-3);
}

.profile-links {
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin-top: 2rem;
}

.social-link {
    color: var(--text-color);
    font-size: 2rem;
    transition: all 0.3s ease;
    filter: drop-shadow(0 0 2px var(--accent-color));
}

.social-link:hover {
    color: var(--accent-color);
    transform: translateY(-2px) scale(1.1);
    filter: drop-shadow(0 0 5px var(--accent-color));
}

.nft-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 20px;
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
    animation: fadeIn 1s ease-out;
}

.album-info {
    text-align: center;
    margin-bottom: 2rem;
    padding: 1.5rem;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 15px;
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.album-info h2 {
    color: var(--accent-color);
    font-size: 1.8rem;
    margin-bottom: 1rem;
}

.album-info p {
    color: var(--text-color);
    font-size: 1.1rem;
}

.error-message {
    background: rgba(255, 68, 68, 0.1);
    color: #ff4444;
    padding: 1rem;
    border-radius: 12px;
    margin-bottom: 2rem;
    border: 1px solid rgba(255, 68, 68, 0.2);
    text-align: center;
}

.nft-content {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 2rem;
    margin-bottom: 2rem;
}

.nft-list {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 15px;
    padding: 1.5rem;
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.nft-list h2 {
    color: var(--accent-color);
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
    text-align: center;
}

.nft-items {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.nft-item {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 12px;
    padding: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.nft-item:hover {
    background: rgba(255, 255, 255, 0.1);
    transform: translateY(-2px);
}

.nft-item.selected {
    background: rgba(0, 255, 157, 0.1);
    border-color: var(--accent-color);
    box-shadow: 0 0 10px rgba(0, 255, 157, 0.2);
}

.nft-item h3 {
    color: #fff;
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
}

.nft-item p {
    color: var(--accent-color-2);
    font-size: 0.9rem;
    margin: 0.25rem 0;
}

.preview-section {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 15px;
    padding: 1.5rem;
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.preview-section h2 {
    color: var(--accent-color);
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
    text-align: center;
}

.empty-state {
    text-align: center;
    color: var(--accent-color-2);
    padding: 2rem;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.wallet-section {
    display: flex;
    justify-content: center;
    margin: 2rem 0;
}

.wallet-info {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.wallet-display {
    background: rgba(255, 255, 255, 0.1);
    padding: 0.75rem 1.5rem;
    border-radius: 12px;
    color: #fff;
    font-family: monospace;
    border: 1px solid rgba(255, 255, 255, 0.2);
}

.connect-button,
.disconnect-button {
    padding: 0.75rem 1.5rem;
    border-radius: 12px;
    font-weight: 500;
    letter-spacing: 1px;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
    z-index: 1;
    text-transform: uppercase;
    font-size: 0.9rem;
    cursor: pointer;
    border: 1px solid var(--accent-color);
    background: transparent;
    color: var(--text-color);
}

.connect-button::before,
.disconnect-button::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg,
        var(--accent-color),
        var(--accent-color-2),
        var(--accent-color-3)
    );
    opacity: 0;
    transition: all 0.3s ease;
    z-index: -1;
}

.connect-button:hover:not(:disabled),
.disconnect-button:hover {
    color: var(--primary-color);
    transform: translateY(-2px);
    border-color: transparent;
    box-shadow: 
        0 0 20px rgba(0, 255, 157, 0.4),
        0 0 30px rgba(0, 255, 157, 0.2),
        0 0 40px rgba(0, 255, 157, 0.1);
}

.connect-button:hover:not(:disabled)::before,
.disconnect-button:hover::before {
    opacity: 1;
}

.connect-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    border-color: rgba(255, 255, 255, 0.2);
}

.disconnect-button {
    background: rgba(255, 68, 68, 0.1);
    border-color: #ff4444;
}

.disconnect-button:hover {
    background: rgba(255, 68, 68, 0.2);
}

.button-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 2rem;
}

.back-home-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    background: transparent;
    color: var(--text-color);
    padding: 1rem 3rem;
    border-radius: 12px;
    font-weight: 500;
    letter-spacing: 2px;
    transition: all 0.3s ease;
    position: relative;
    border: 1px solid var(--accent-color);
    box-shadow: 0 0 10px rgba(0, 255, 157, 0.2);
    text-transform: uppercase;
    font-size: 0.9rem;
    overflow: hidden;
    z-index: 1;
}

.back-home-btn::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg,
        var(--accent-color),
        var(--accent-color-2),
        var(--accent-color-3)
    );
    opacity: 0;
    transition: all 0.3s ease;
    z-index: -1;
}

.back-home-btn:hover {
    color: var(--primary-color);
    transform: translateY(-2px);
    border-color: transparent;
    box-shadow: 
        0 0 20px rgba(0, 255, 157, 0.4),
        0 0 30px rgba(0, 255, 157, 0.2),
        0 0 40px rgba(0, 255, 157, 0.1);
}

.back-home-btn:hover::before {
    opacity: 1;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes gradientShift {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

/* Responsive Design */
@media (max-width: 1024px) {
    .nft-content {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 768px) {
    .nft-page {
        padding: 1rem;
    }
    .profile-header h1 {
        font-size: 2.5rem;
    }
    .nft-container {
        padding: 1.5rem;
    }
    .album-info h2 {
        font-size: 1.5rem;
    }
    .album-info p {
        font-size: 1rem;
    }
    .nft-list h2,
    .preview-section h2 {
        font-size: 1.3rem;
    }
    .nft-item h3 {
        font-size: 1.1rem;
    }
    .wallet-section {
        flex-direction: column;
        gap: 1rem;
    }
    .wallet-info {
        flex-direction: column;
    }
    .wallet-display {
        width: 100%;
        text-align: center;
    }
}

@media (max-width: 480px) {
    .profile-header h1 {
        font-size: 2rem;
    }
    .nft-container {
        padding: 1rem;
    }
    .album-info {
        padding: 1rem;
    }
    .album-info h2 {
        font-size: 1.3rem;
    }
    .nft-list,
    .preview-section {
        padding: 1rem;
    }
    .nft-item {
        padding: 0.8rem;
    }
    .nft-item h3 {
        font-size: 1rem;
    }
    .connect-button,
    .disconnect-button,
    .back-home-btn {
        padding: 0.7rem 1.5rem;
        font-size: 0.8rem;
    }
}

/* Fix for very small screens */
@media (max-width: 360px) {
    .nft-page {
        padding: 0.8rem;
    }
    .profile-header h1 {
        font-size: 1.8rem;
    }
    .nft-container {
        padding: 0.8rem;
    }
    .album-info {
        padding: 0.8rem;
    }
    .album-info h2 {
        font-size: 1.2rem;
    }
    .album-info p {
        font-size: 0.9rem;
    }
    .nft-list,
    .preview-section {
        padding: 0.8rem;
    }
    .nft-item {
        padding: 0.6rem;
    }
    .nft-item h3 {
        font-size: 0.9rem;
    }
    .connect-button,
    .disconnect-button,
    .back-home-btn {
        padding: 0.6rem 1.2rem;
        font-size: 0.75rem;
    }
}

/* Fix for landscape orientation on mobile */
@media (max-height: 600px) and (orientation: landscape) {
    .nft-page {
        padding: 1rem;
    }
    .profile-header {
        margin-bottom: 2rem;
    }
    .nft-container {
        padding: 1rem;
    }
    .nft-content {
        margin-bottom: 1rem;
    }
    .wallet-section {
        margin: 1rem 0;
    }
}
