.vlog-page {
    min-height: 100vh;
    background: linear-gradient(135deg, #1a1a1a, #2d2d2d);
    color: #e0e0e0;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, sans-serif;
    padding: 2rem;
    position: relative;
    overflow: hidden;
    max-width: 1200px;
    margin: 0 auto;
}

.profile-header {
    text-align: center;
    margin-bottom: 3rem;
    animation: fadeIn 1s ease-out;
}

.profile-header h1 {
    font-size: 3rem;
    font-weight: 900;
    margin-bottom: 1.5rem;
    color: #ffffff;
    position: relative;
    display: inline-block;
}

.profile-header h1::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -8px;
    width: 100%;
    height: 2px;
    background: linear-gradient(45deg, 
        #4a90e2 0%,
        #5c6bc0 50%,
        #3f51b5 100%
    );
    background-size: 200% 200%;
    animation: gradientShift 5s ease infinite;
    box-shadow:
        0 0 5px rgba(74, 144, 226, 0.3),
        0 0 10px rgba(74, 144, 226, 0.2),
        0 0 15px rgba(74, 144, 226, 0.1);
}

.profile-links {
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin-top: 2rem;
}

.social-link {
    color: #b0b0b0;
    font-size: 2rem;
    transition: all 0.3s ease;
    filter: drop-shadow(0 0 2px rgba(74, 144, 226, 0.3));
}

.social-link:hover {
    color: #4a90e2;
    transform: translateY(-2px) scale(1.1);
    filter: drop-shadow(0 0 5px rgba(74, 144, 226, 0.5));
}

.vlog-container {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    padding: 2rem;
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.05);
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
    animation: fadeIn 1s ease-out;
}

.vlog-content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.video-section {
    position: relative;
    width: 100%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
    background: rgba(0, 0, 0, 0.3);
    border-radius: 15px;
    overflow: hidden;
}

.video-section iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
}

.video-info {
    padding: 1.5rem;
    background: rgba(255, 255, 255, 0.03);
    border-radius: 15px;
    border: 1px solid rgba(255, 255, 255, 0.05);
}

.video-info h2 {
    color: #ffffff;
    font-size: 1.8rem;
    margin-bottom: 1rem;
}

.video-description {
    color: #b0b0b0;
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 1rem;
}

.video-meta {
    display: flex;
    gap: 1rem;
    color: #4a90e2;
    font-size: 0.9rem;
}

.button-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 3rem;
}

.back-home-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    background: transparent;
    color: #e0e0e0;
    padding: 1rem 3rem;
    border-radius: 12px;
    font-weight: 500;
    letter-spacing: 2px;
    transition: all 0.3s ease;
    position: relative;
    border: 1px solid #4a90e2;
    box-shadow: 0 0 10px rgba(74, 144, 226, 0.2);
    text-transform: uppercase;
    font-size: 0.9rem;
    overflow: hidden;
    z-index: 1;
}

.back-home-btn::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg,
        #4a90e2,
        #5c6bc0,
        #3f51b5
    );
    opacity: 0;
    transition: all 0.3s ease;
    z-index: -1;
}

.back-home-btn:hover {
    color: #ffffff;
    transform: translateY(-2px);
    border-color: transparent;
    box-shadow: 
        0 0 20px rgba(74, 144, 226, 0.3),
        0 0 30px rgba(74, 144, 226, 0.2),
        0 0 40px rgba(74, 144, 226, 0.1);
}

.back-home-btn:hover::before {
    opacity: 1;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes gradientShift {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

/* Responsive Design */
@media (max-width: 1024px) {
    .vlog-page {
        padding: 1.5rem;
    }
    
    .vlog-container {
        padding: 1.5rem;
    }
}

@media (max-width: 768px) {
    .vlog-page {
        padding: 1rem;
    }
    
    .profile-header h1 {
        font-size: 2.5rem;
    }
    
    .vlog-container {
        padding: 1rem;
    }
    
    .video-info h2 {
        font-size: 1.5rem;
    }
    
    .video-description {
        font-size: 0.9rem;
    }
}

@media (max-width: 480px) {
    .profile-header h1 {
        font-size: 2rem;
    }
    
    .profile-links {
        gap: 1.5rem;
    }
    
    .social-link {
        font-size: 1.5rem;
    }
    
    .video-info {
        padding: 1rem;
    }
    
    .video-info h2 {
        font-size: 1.3rem;
    }
    
    .video-description {
        font-size: 0.85rem;
    }
    
    .video-meta {
        font-size: 0.8rem;
    }
    
    .back-home-btn {
        padding: 0.8rem 2rem;
        font-size: 0.8rem;
    }
}

/* Fix for very small screens */
@media (max-width: 360px) {
    .vlog-page {
        padding: 0.8rem;
    }
    
    .profile-header h1 {
        font-size: 1.8rem;
    }
    
    .vlog-container {
        padding: 0.8rem;
    }
    
    .video-info {
        padding: 0.8rem;
    }
    
    .video-info h2 {
        font-size: 1.1rem;
    }
    
    .video-description {
        font-size: 0.8rem;
    }
    
    .back-home-btn {
        padding: 0.6rem 1.5rem;
        font-size: 0.75rem;
    }
}

/* Fix for landscape orientation on mobile */
@media (max-height: 600px) and (orientation: landscape) {
    .vlog-page {
        padding: 1rem;
    }
    
    .profile-header {
        margin-bottom: 2rem;
    }
    
    .vlog-container {
        padding: 1rem;
    }
    
    .button-container {
        margin-top: 2rem;
    }
} 
