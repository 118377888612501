.me-page {
    min-height: 100vh;
    padding: 2rem;
    padding-left: 6rem;
    background: var(--background);
    color: var(--text);
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, sans-serif;
    position: relative;
    overflow: hidden;
    max-width: 1200px;
    margin: 0 auto;
    background: var(--secondary-color);
}

.me-page .content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 1rem;
}

.profile-header {
    text-align: center;
    margin-bottom: 4rem;
    animation: fadeIn 1s ease-out;
}

.profile-header h1 {
    font-size: 3rem;
    font-weight: 900;
    margin-bottom: 1.5rem;
    color: #fff;
    position: relative;
    display: inline-block;
}

.profile-header h1::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -8px;
    width: 100%;
    height: 2px;
    background: linear-gradient(45deg, 
        var(--accent-color) 0%,
        var(--accent-color-2) 50%,
        var(--accent-color-3) 100%
    );
    background-size: 200% 200%;
    animation: gradientShift 5s ease infinite;
    box-shadow:
        0 0 5px var(--accent-color),
        0 0 10px var(--accent-color-2),
        0 0 15px var(--accent-color-3);
}

.profile-links {
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin-top: 2rem;
}

.social-link {
    color: var(--text-color);
    font-size: 2rem;
    transition: all 0.3s ease;
    filter: drop-shadow(0 0 2px var(--accent-color));
}

.social-link:hover {
    color: var(--accent-color);
    transform: translateY(-2px) scale(1.1);
    filter: drop-shadow(0 0 5px var(--accent-color));
}

/* Timeline Container */
.timeline-container {
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    padding: 2rem 0;
}

.timeline-line {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 2px;
    height: 100%;
    background: linear-gradient(to bottom,
        var(--accent-color),
        var(--accent-color-2),
        var(--accent-color-3)
    );
    opacity: 0.5;
    box-shadow: 0 0 10px var(--accent-color);
}

/* Timeline Items */
.timeline-item {
    width: 100%;
    margin-bottom: 3rem;
    position: relative;
    display: flex;
    justify-content: center;
    opacity: 1;
    transform: translateX(0);
    transition: all 0.5s ease;
}

.timeline-item.left .timeline-content {
    margin-right: 50%;
    padding-right: 3rem;
    text-align: right;
}

.timeline-item.right .timeline-content {
    margin-left: 50%;
    padding-left: 3rem;
    text-align: left;
}

.timeline-content {
    width: 45%;
    background: var(--box-bg);
    padding: 1.5rem;
    border-radius: 15px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    position: relative;
    animation: fadeIn 1s ease-out;
    transition: all 0.3s ease;
    z-index: 1;
}

.timeline-content:hover {
    transform: translateY(-5px);
    border-color: var(--accent-color);
    box-shadow: var(--neon-glow);
}

.timeline-icon {
    width: 40px;
    height: 40px;
    background: var(--accent-color);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    box-shadow: 0 0 15px var(--accent-color);
    z-index: 2;
}

.timeline-item.left .timeline-icon {
    right: -20px;
    transform: translate(50%, -50%);
}

.timeline-item.right .timeline-icon {
    left: -20px;
    transform: translate(-50%, -50%);
}

.timeline-icon svg {
    font-size: 1.2rem;
    color: var(--primary-color);
}

.timeline-date {
    font-size: 0.9rem;
    color: var(--accent-color);
    margin-bottom: 0.5rem;
    font-weight: 500;
}

.timeline-title {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
    color: #fff;
}

.timeline-subtitle {
    font-size: 1rem;
    color: var(--accent-color-2);
    margin-bottom: 0.5rem;
}

.timeline-description {
    font-size: 0.9rem;
    line-height: 1.6;
    color: rgba(255, 255, 255, 0.8);
}

.timeline-link {
    color: var(--accent-color);
    text-decoration: none;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
    line-height: 1.8;
}

.timeline-link:hover {
    color: var(--accent-color-2);
    text-shadow: 0 0 8px var(--accent-color-2);
}

.timeline-link::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 0;
    background: linear-gradient(90deg,
        var(--accent-color) 0%,
        var(--accent-color-2) 100%
    );
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.3s ease;
}

.timeline-link:hover::after {
    transform: scaleX(1);
    transform-origin: left;
}

/* Add a container for the button */
.button-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 4rem 0;
    padding: 0 1rem;
}

.back-home-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    background: transparent;
    color: var(--text-color);
    padding: 1rem 3rem;
    border-radius: 12px;
    font-weight: 500;
    letter-spacing: 2px;
    transition: all 0.3s ease;
    position: relative;
    border: 1px solid var(--accent-color);
    box-shadow: 0 0 10px rgba(0, 255, 157, 0.2);
    text-transform: uppercase;
    font-size: 0.9rem;
    overflow: hidden;
    z-index: 1;
}

.back-home-btn::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg,
        var(--accent-color),
        var(--accent-color-2),
        var(--accent-color-3)
    );
    opacity: 0;
    transition: all 0.3s ease;
    z-index: -1;
}

.back-home-btn:hover {
    color: var(--primary-color);
    transform: translateY(-2px);
    border-color: transparent;
    box-shadow: 
        0 0 20px rgba(0, 255, 157, 0.4),
        0 0 30px rgba(0, 255, 157, 0.2),
        0 0 40px rgba(0, 255, 157, 0.1);
}

.back-home-btn:hover::before {
    opacity: 1;
}

/* Update responsive styles for the button */
@media (max-width: 768px) {
    .me-page {
        padding: 1.5rem;
        padding-left: 4rem;
    }

    .profile-header h1 {
        font-size: 2.5rem;
    }

    .timeline-container {
        padding: 1rem 0;
    }

    .timeline-line {
        left: 30px;
    }

    .timeline-item.left .timeline-content,
    .timeline-item.right .timeline-content {
        width: calc(100% - 60px);
        margin-left: 60px;
        padding-left: 1.5rem;
        text-align: left;
    }

    .timeline-item.left .timeline-icon,
    .timeline-item.right .timeline-icon {
        left: 30px;
        transform: translate(-50%, -50%);
    }

    .timeline-content {
        padding: 1rem;
    }

    .timeline-title {
        font-size: 1.1rem;
    }

    .timeline-subtitle {
        font-size: 0.9rem;
    }

    .timeline-description {
        font-size: 0.8rem;
    }

    .button-container {
        margin: 3rem 0;
    }

    .back-home-btn {
        padding: 0.8rem 2rem;
        font-size: 0.8rem;
        letter-spacing: 1.5px;
    }

    .timeline-link {
        line-height: 2;
        font-size: 0.85rem;
    }

    .timeline-icon {
        width: 35px;
        height: 35px;
    }

    .timeline-item.left .timeline-icon,
    .timeline-item.right .timeline-icon {
        left: 25px;
    }

    .timeline-section {
        margin-left: 0.5rem;
    }

    .filter-buttons {
        margin-left: 0.5rem;
    }
}

@media (max-width: 480px) {
    .timeline-icon {
        display: none;
    }

    .timeline-line {
        display: none;
    }

    .timeline-item.left .timeline-content,
    .timeline-item.right .timeline-content {
        width: 100%;
        margin-left: 0;
        padding-left: 1rem;
        text-align: left;
    }

    .timeline-item.left .timeline-content {
        margin-right: 0;
        padding-right: 0;
    }

    .timeline-item.right .timeline-content {
        margin-left: 0;
        padding-left: 1rem;
    }

    .timeline-content {
        width: 100%;
        padding: 1rem;
    }

    .me-page {
        padding: 1rem;
        padding-left: 3.5rem;
    }

    .timeline-section {
        margin-left: 0.3rem;
    }

    .filter-buttons {
        margin-left: 0.3rem;
        gap: 0.5rem;
    }
}

/* Animations */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes gradientShift {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.overview-section {
    padding: 2rem;
    margin: 2rem auto;
    max-width: 1200px;
    background: rgba(18, 18, 18, 0.6);
    border-radius: 1.5rem;
    backdrop-filter: blur(10px);
}

.overview-section h1 {
    font-size: 3rem;
    color: #00ff9d;
    text-align: center;
    margin: 2rem 0 3rem;
    font-weight: 600;
}

.overview-blocks {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    padding: 0 2rem 2rem;
}

.overview-block {
    background: rgba(30, 30, 30, 0.8);
    border-radius: 1rem;
    padding: 2rem;
    min-height: 300px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.overview-block:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0, 255, 157, 0.1);
}

.overview-block h2 {
    font-size: 1.8rem;
    color: #00ff9d;
    margin-bottom: 1.5rem;
    text-align: center;
}

.overview-block ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.overview-block li {
    font-size: 1.1rem;
    line-height: 1.6;
    margin: 1rem 0;
    padding-left: 1.5rem;
    position: relative;
    color: #fff;
}

.overview-block li::before {
    content: "•";
    color: #00ff9d;
    position: absolute;
    left: 0;
    top: 0;
}

/* Responsive styles */
@media (max-width: 1024px) {
    .overview-section {
        margin: 1.5rem;
    }

    .overview-blocks {
        gap: 1.5rem;
        padding: 0 1.5rem 1.5rem;
    }

    .overview-block {
        min-height: 250px;
    }
}

@media (max-width: 768px) {
    .overview-section h1 {
        font-size: 2.5rem;
        margin: 1.5rem 0 2rem;
    }

    .overview-blocks {
        grid-template-columns: 1fr;
        gap: 1.5rem;
        padding: 0 1.5rem 1.5rem;
    }

    .overview-block {
        min-height: auto;
        padding: 1.5rem;
    }

    .overview-block h2 {
        font-size: 1.6rem;
    }

    .overview-block li {
        font-size: 1rem;
        margin: 0.8rem 0;
    }
}

@media (max-width: 480px) {
    .overview-section {
        margin: 1rem;
        padding: 1rem;
    }

    .overview-section h1 {
        font-size: 2rem;
        margin: 1rem 0 1.5rem;
    }

    .overview-blocks {
        padding: 0 1rem 1rem;
        gap: 1rem;
    }

    .overview-block {
        padding: 1.2rem;
    }

    .overview-block h2 {
        font-size: 1.4rem;
        margin-bottom: 1rem;
    }

    .overview-block li {
        font-size: 0.95rem;
        padding-left: 1.2rem;
    }
}

/* Dark mode optimization */
@media (prefers-color-scheme: dark) {
    .overview-section {
        background: rgba(18, 18, 18, 0.8);
    }

    .overview-block {
        background: rgba(30, 30, 30, 0.9);
    }
}

.overview {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    padding: 2rem;
    margin: 2rem 0;
    width: 100%;
    box-sizing: border-box;
}

.overview-item {
    background: rgba(30, 30, 30, 0.6);
    border-radius: 1rem;
    padding: 2rem;
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.1);
    min-width: 250px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.overview-item h2 {
    color: var(--accent-color);
    font-size: 2rem;
    margin-bottom: 1.5rem;
}

.overview-item ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.overview-item li {
    margin: 1rem 0;
    font-size: 1.1rem;
    line-height: 1.5;
}

@media (max-width: 768px) {
    .overview {
        grid-template-columns: 1fr;
        padding: 1rem;
        gap: 1.5rem;
        margin: 1rem 0;
    }

    .overview-item {
        padding: 1.5rem;
        min-width: unset;
    }

    .overview-item h2 {
        font-size: 1.8rem;
        margin-bottom: 1rem;
    }

    .overview-item li {
        font-size: 1rem;
    }
}

@media (max-width: 480px) {
    .overview {
        padding: 0.8rem;
        gap: 1rem;
    }

    .overview-item {
        padding: 1.2rem;
    }

    .overview-item h2 {
        font-size: 1.6rem;
    }
}

.overview-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.timeline-item.education {
    border-left: 3px solid var(--accent-color);
}

.timeline-item.work {
    border-left: 3px solid var(--accent-color-2);
}

.timeline-item.certification {
    border-left: 3px solid var(--accent-color-3);
}

.timeline-icon.education {
    background: var(--accent-color);
}

.timeline-icon.work {
    background: var(--accent-color-2);
}

.timeline-icon.certification {
    background: var(--accent-color-3);
}

.certificate-links {
    margin-bottom: 1.5rem;
}

.certificate-item {
    margin-bottom: 1.5rem;
    padding: 1rem;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    transition: transform 0.3s ease;
}

.certificate-item:hover {
    transform: translateX(5px);
}

.certificate-item h4 {
    color: var(--accent-color);
    margin-bottom: 0.8rem;
    font-size: 1.1rem;
}

.certificate-item ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.certificate-item li {
    margin-bottom: 0.5rem;
    padding-left: 1.5rem;
    position: relative;
    color: rgba(255, 255, 255, 0.8);
}

.certificate-item li::before {
    content: "•";
    color: var(--accent-color);
    position: absolute;
    left: 0;
}

.certificate-details {
    margin-top: 1.5rem;
    padding-top: 1.5rem;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.certificate-details p {
    margin: 0.5rem 0;
    color: rgba(255, 255, 255, 0.8);
}

@media (max-width: 768px) {
    .certificate-item {
        padding: 0.8rem;
    }

    .certificate-item h4 {
        font-size: 1rem;
    }

    .certificate-item li {
        font-size: 0.9rem;
    }
}

.filter-section {
    margin: 2rem 0;
    padding: 1rem;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    backdrop-filter: blur(10px);
}

.filter-buttons {
    margin-left: 1rem;
    margin-bottom: 2rem;
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    position: relative;
}

.filter-btn {
    padding: 0.8rem 1.5rem;
    border: none;
    border-radius: 25px;
    background: rgba(255, 255, 255, 0.1);
    color: var(--text-color);
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
    min-width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.filter-btn::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, var(--accent-color), var(--accent-color-2));
    opacity: 0;
    transition: opacity 0.3s ease;
    z-index: 0;
}

.filter-btn:hover::before {
    opacity: 0.2;
}

.filter-btn.active {
    background: linear-gradient(45deg, var(--accent-color), var(--accent-color-2));
    box-shadow: 0 0 15px rgba(0, 255, 187, 0.3);
    transform: translateY(-2px);
}

.filter-btn.active:hover {
    transform: translateY(-2px) scale(1.05);
}

.clear-filter {
    padding: 0.8rem;
    border: none;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.1);
    color: var(--text-color);
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: fadeIn 0.3s ease;
}

.clear-filter:hover {
    background: rgba(255, 255, 255, 0.2);
    transform: rotate(90deg);
}

.timeline-item.hidden {
    opacity: 0;
    transform: translateX(-100px);
    pointer-events: none;
}

.timeline-item.right.hidden {
    transform: translateX(100px);
}

@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.filter-btn::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 2px;
    background: var(--accent-color);
    transition: all 0.3s ease;
    transform: translateX(-50%);
}

.filter-btn:hover::after {
    width: 80%;
}

.filter-btn.active::after {
    width: 80%;
    background: var(--accent-color-2);
}

@media (max-width: 768px) {
    .filter-buttons {
        gap: 0.5rem;
    }

    .filter-btn {
        padding: 0.6rem 1.2rem;
        font-size: 0.9rem;
        min-width: 100px;
    }

    .clear-filter {
        padding: 0.6rem;
    }
}

.filter-btn[data-category="all"] {
    border-color: var(--accent-color);
}
.filter-btn[data-category="all"]::before {
    background: linear-gradient(45deg, var(--accent-color), var(--accent-color-2));
}
.filter-btn[data-category="all"].active {
    background: linear-gradient(45deg, var(--accent-color), var(--accent-color-2));
    box-shadow: 0 0 15px rgba(0, 255, 187, 0.3);
}

.filter-btn[data-category="education"] {
    border-color: var(--accent-color);
}
.filter-btn[data-category="education"]::before {
    background: linear-gradient(45deg, var(--accent-color), var(--accent-color-2));
}
.filter-btn[data-category="education"].active {
    background: linear-gradient(45deg, var(--accent-color), var(--accent-color-2));
    box-shadow: 0 0 15px rgba(0, 255, 187, 0.3);
}

.filter-btn[data-category="work"] {
    border-color: var(--accent-color-2);
}
.filter-btn[data-category="work"]::before {
    background: linear-gradient(45deg, var(--accent-color-2), var(--accent-color-3));
}
.filter-btn[data-category="work"].active {
    background: linear-gradient(45deg, var(--accent-color-2), var(--accent-color-3));
    box-shadow: 0 0 15px rgba(77, 77, 255, 0.3);
}

.filter-btn[data-category="certification"] {
    border-color: var(--accent-color-3);
}
.filter-btn[data-category="certification"]::before {
    background: linear-gradient(45deg, var(--accent-color-3), var(--accent-color));
}
.filter-btn[data-category="certification"].active {
    background: linear-gradient(45deg, var(--accent-color-3), var(--accent-color));
    box-shadow: 0 0 15px rgba(255, 51, 153, 0.3);
}

.timeline-item.education {
    border-left: 3px solid var(--accent-color);
}
.timeline-item.education .timeline-icon {
    background: var(--accent-color);
    box-shadow: 0 0 15px rgba(0, 255, 187, 0.3);
}

.timeline-item.work {
    border-left: 3px solid var(--accent-color-2);
}
.timeline-item.work .timeline-icon {
    background: var(--accent-color-2);
    box-shadow: 0 0 15px rgba(77, 77, 255, 0.3);
}

.timeline-item.certification {
    border-left: 3px solid var(--accent-color-3);
}
.timeline-item.certification .timeline-icon {
    background: var(--accent-color-3);
    box-shadow: 0 0 15px rgba(255, 51, 153, 0.3);
}

.timeline-item.hidden {
    opacity: 0;
    transform: translateX(-100px);
    pointer-events: none;
}

.timeline-item.right.hidden {
    transform: translateX(100px);
}

.timeline-item.active {
    animation: slideIn 0.5s ease forwards;
}

@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Responsive Design Updates */
@media (max-width: 1200px) {
  .me-page {
    padding: 1.5rem;
  }

  .timeline-container {
    max-width: 100%;
  }

  .timeline-content {
    width: 50%;
  }
}

@media (max-width: 992px) {
    .timeline-line {
        left: 30px;
    }

    .timeline-item.left .timeline-content,
    .timeline-item.right .timeline-content {
        width: calc(100% - 60px);
        margin-left: 60px;
        padding-left: 1.5rem;
        text-align: left;
    }

    .timeline-item.left .timeline-icon,
    .timeline-item.right .timeline-icon {
        left: 30px;
        transform: translate(-50%, -50%);
    }

    .timeline-item.left .timeline-content {
        margin-right: 0;
        padding-right: 0;
    }

    .timeline-item.right .timeline-content {
        margin-left: 60px;
        padding-left: 1.5rem;
    }
}

@media (max-width: 768px) {
    .timeline-icon {
        width: 35px;
        height: 35px;
    }

    .timeline-item.left .timeline-icon,
    .timeline-item.right .timeline-icon {
        left: 25px;
    }

    .timeline-line {
        left: 25px;
    }

    .timeline-item.left .timeline-content,
    .timeline-item.right .timeline-content {
        width: calc(100% - 50px);
        margin-left: 50px;
    }
}

@media (max-width: 480px) {
    .timeline-icon {
        width: 30px;
        height: 30px;
    }

    .timeline-item.left .timeline-icon,
    .timeline-item.right .timeline-icon {
        left: 20px;
    }

    .timeline-line {
        left: 20px;
    }

    .timeline-item.left .timeline-content,
    .timeline-item.right .timeline-content {
        width: calc(100% - 40px);
        margin-left: 40px;
        padding-left: 1rem;
    }
}

/* Fix for very small screens */
@media (max-width: 360px) {
    .me-page {
        padding: 0.8rem;
    }

    .profile-header h1 {
        font-size: 1.8rem;
    }

    .overview-section {
        padding: 1rem;
    }

    .filter-btn {
        min-width: 80px;
        padding: 0.4rem 0.8rem;
    }

    .timeline-content {
        padding: 0.8rem;
    }

    .timeline-title {
        font-size: 1rem;
    }

    .timeline-subtitle {
        font-size: 0.85rem;
    }

    .timeline-description {
        font-size: 0.75rem;
    }
}

/* Fix for landscape orientation on mobile */
@media (max-height: 600px) and (orientation: landscape) {
  .me-page {
    padding: 1rem;
  }

  .profile-header {
    margin-bottom: 2rem;
  }

  .overview-section {
    margin-bottom: 2rem;
  }

  .timeline-container {
    padding: 1rem 0;
  }

  .timeline-item {
    margin-bottom: 2rem;
  }

  .button-container {
    margin: 2rem 0;
  }
}

.timeline-section {
    margin-left: 1rem;
}

.nav-icons {
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    z-index: 100;
    left: 2rem;
    transition: all 0.3s ease;
}

.nav-icons a {
    color: var(--accent-color);
    font-size: 1.5rem;
    transition: all 0.3s ease;
    opacity: 0.8;
}

.nav-icons a:hover {
    color: var(--text-color);
    transform: scale(1.1);
    opacity: 1;
}

/* Responsive navigation */
@media (max-width: 1400px) {
    .nav-icons {
        top: 50%;
        left: 30px;
        transform: translateY(-50%);
        flex-direction: column;
        gap: 1.2rem;
        background: none;
        border: none;
    }

    .nav-icons a {
        font-size: 1.2rem;
    }
}

@media (max-width: 768px) {
    .nav-icons {
        left: 30px;
        gap: 1rem;
    }

    .nav-icons a {
        font-size: 1.1rem;
    }

    .timeline-line {
        left: 30px;
    }

    .timeline-item.left .timeline-icon,
    .timeline-item.right .timeline-icon {
        left: 30px;
    }

    .timeline-item.left .timeline-content,
    .timeline-item.right .timeline-content {
        width: calc(100% - 80px);
        margin-left: 80px;
        padding-left: 1.5rem;
    }

    .timeline-section {
        margin-left: 0;
        padding-left: 80px;
    }

    .filter-buttons {
        margin-left: 0;
        padding-left: 80px;
    }

    .overview-section {
        padding-left: 80px;
    }
}

@media (max-width: 480px) {
    .nav-icons {
        left: 20px;
        gap: 0.8rem;
    }

    .nav-icons a {
        font-size: 1rem;
    }

    .timeline-line {
        left: 20px;
    }

    .timeline-item.left .timeline-icon,
    .timeline-item.right .timeline-icon {
        left: 20px;
    }

    .timeline-item.left .timeline-content,
    .timeline-item.right .timeline-content {
        width: calc(100% - 60px);
        margin-left: 60px;
        padding-left: 1rem;
    }

    .timeline-section {
        padding-left: 60px;
    }

    .filter-buttons {
        padding-left: 60px;
    }

    .overview-section {
        padding-left: 60px;
    }
}

/* Portrait mode */
@media (orientation: portrait) {
    .nav-icons {
        display: none;
    }
}

/* Landscape mode */
@media (orientation: landscape) {
    .nav-icons {
        display: flex;
    }
}
